import { LitElement, css, html } from 'lit';
import { property, customElement } from 'lit/decorators.js';

// For more info on the @pwabuilder/pwainstall component click here https://github.com/pwa-builder/pwa-install
import '@pwabuilder/pwainstall';

import '@shoelace-style/shoelace/dist/components/card/card.js';
import '@shoelace-style/shoelace/dist/components/button/button.js';

import { styles } from '../styles/shared-styles';

@customElement('app-home')
export class AppHome extends LitElement {
  // For more information on using properties and state in lit
  // check out this link https://lit.dev/docs/components/properties/
  @property() message = 'PensionCalc';

  static get styles() {
    return [
      styles,
      css`
        #welcomeBar {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        }

        #welcomeCard,
        #infoCard {
          padding: 18px;
          padding-top: 0px;
        }

        #jHomeButtons {
          display: flex;
          justify-content: center;
        }

        #jHomeBlogButton,
        #jHomeCalcButton,
        #jHomeCalcButton2,
        #jHomeCalcButton3 {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        }

        pwa-install {
          position: absolute;
          bottom: 16px;
          right: 16px;
        }

        sl-card::part(footer) {
          display: flex;
          justify-content: flex-end;
        }

        @media (min-width: 750px) {
          sl-card {
            width: 70vw;
          }
        }

        @media (horizontal-viewport-segments: 2) {
          #welcomeBar {
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;
          }
        }
      `,
    ];
  }

  constructor() {
    super();
  }

  async firstUpdated() {
    // this method is a lifecycle even in lit
    // for more info check out the lit docs https://lit.dev/docs/components/lifecycle/
    console.log('Welcome to PensionCalc');
  }

  share() {
    if ((navigator as any).share) {
      (navigator as any).share({
        title: 'PensionCalc',
        text: 'Estimate your NHS Pension & more!',
        url: 'https://pensioncalc.co.uk',
      });
    }
  }

  render() {
    return html`
      <main>
        <div id="welcomeBar">
          <sl-card id="welcomeCard">
            <div slot="header">
              <h2>Welcome to PensionCalc</h2>
            </div>

            <p>
              Welcome to PensionCalc, the ultimate toolbox that brings clarity
              to your pension calculations.
            </p>
            <p>
              We help you navigate the complexities of defined-benefit UK
              pension schemes with a variety of precise calculators, each
              tailored to a specific fixed scheme. We do our best to keep our
              tools updated, ensuring you receive the most accurate estimates.
            </p>
          </sl-card>
          <sl-card id="welcomeCard">
            <div slot="header">
              <h3>Our Calculators</h3>
            </div>

            <p>
              Take the guesswork out of your pension planning - Happy
              calculating!
            </p>
            <sl-button
              href="${(import.meta as any).env.BASE_URL}nhs-calc"
              variant="primary"
              id="jHomeCalcButton"
            >
              NHS 2015 Pension
            </sl-button>
            <br />
            <sl-button
              href="${(import.meta as any).env.BASE_URL}teacher-calc"
              variant="primary"
              id="jHomeCalcButton2"
            >
              Teacher's Pension
            </sl-button>

            <br />
            <sl-button
              href="${(import.meta as any).env.BASE_URL}civil-calc"
              variant="primary"
              id="jHomeCalcButton3"
            >
              Civil Service Pension
            </sl-button> </sl-card
          ><sl-card id="welcomeCard">
            <div slot="header">
              <h3>Resources</h3>
            </div>

            <p>
              Find out more about your pension scheme and how it works by
              reading our articles below.
            </p>
            <ul>
              <li>
                <a href="/blog/2015-nhs-pension-contributions-benefits"
                  >2015 NHS Pension Scheme - Contributions vs. Benefits</a
                >
              </li>
              <li>
                <a href="/blog/nhs-pension-should-i-opt-out"
                  >Should I opt out of the NHS Pension?</a
                >
              </li>
              <li>
                <a href="/blog/nhs-pension-early-retirement-worth-it"
                  >NHS Pension - Is early retirement worth it?</a
                >
              </li>
              <li>
                <a href="/blog/teachers-pension-scheme-contributions-benefits"
                  >Teacher's Pension Scheme - Contributions vs. Benefits</a
                >
              </li>
              <li>
                <a
                  href="/blog/civil-service-pension-scheme-contributions-benefits"
                  >Civil Service Pension Scheme - Contributions vs. Benefits</a
                >
              </li>
            </ul>
          </sl-card>
          <sl-card id="welcomeCard">
            <p>
              Would you like to support this project?

              <a href="https://buy.stripe.com/3cs4iy0gNeCL0I86oo"
                >Buy us a coffee!</a
              >
              &hearts;
            </p>
            <p>
              All calculations happen in your browser. We store minimal personal
              data.
            </p>
            <div slot="footer">
              <a href="/terms">Terms</a> <a href="/privacy">Privacy</a>
            </div>
          </sl-card>
        </div>
      </main>
    `;
  }
}
