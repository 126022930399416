import { LitElement, css, html } from 'lit';
import { customElement } from 'lit/decorators.js';
import { Router } from '@vaadin/router';

import './pages/app-home';
import './components/header';
import './styles/global.css';
// import './components/cookie-consent';

@customElement('app-index')
export class AppIndex extends LitElement {
  static get styles() {
    return css`
      main {
        padding-left: 16px;
        padding-right: 16px;
        padding-bottom: 16px;
      }

      #routerOutlet > * {
        width: 100% !important;
      }

      #routerOutlet > .leaving {
        animation: 160ms fadeOut ease-in-out;
      }

      #routerOutlet > .entering {
        animation: 160ms fadeIn linear;
      }

      @keyframes fadeOut {
        from {
          opacity: 1;
        }

        to {
          opacity: 0;
        }
      }

      @keyframes fadeIn {
        from {
          opacity: 0.2;
        }

        to {
          opacity: 1;
        }
      }
    `;
  }

  constructor() {
    super();
  }

  firstUpdated() {
    // this method is a lifecycle even in lit
    // for more info check out the lit docs https://lit.dev/docs/components/lifecycle/

    // For more info on using the @vaadin/router check here https://vaadin.com/router
    const router = new Router(this.shadowRoot?.querySelector('#routerOutlet'));
    router.setRoutes([
      // temporarily cast to any because of a Type bug with the router
      {
        path: (import.meta as any).env.BASE_URL,
        animate: true,
        children: [
          { path: '', component: 'app-home' },

          {
            path: 'nhs-calc',
            component: 'app-calc1',
            action: async () => {
              document.title = 'NHS Pension Scheme Calculator - PensionCalc';
              setMetaDescription(
                "Explore PensionCalc's 2015 NHS Pension Scheme calculator. Use advanced mode to calculate inflation, early retirement and lump sum.",
              );
              await import('./pages/app-calc1.js');
            },
          },
          {
            path: 'teacher-calc',
            component: 'app-calc2',
            action: async () => {
              document.title =
                'Teacher Pension Scheme Calculator - PensionCalc';
              setMetaDescription(
                "Explore PensionCalc's Teachers' Pension Scheme calculator. Use advanced mode to calculate inflation, early retirement and lump sum.",
              );
              await import('./pages/app-calc2.js');
            },
          },
          {
            path: 'civil-calc',
            component: 'app-calc3',
            action: async () => {
              document.title =
                'Civil Service Pension Scheme Calculator - PensionCalc';
              setMetaDescription(
                "Explore PensionCalc's Civil Service Pension Scheme calculator. Use advanced mode to calculate inflation, early retirement and lump sum.",
              );
              await import('./pages/app-calc3.js');
            },
          },
          {
            path: 'blog/nhs-pension-should-i-opt-out',
            component: 'app-blog1',
            action: async () => {
              document.title = 'Should I Opt Out of the NHS Pension Scheme?';
              setMetaDescription(
                'Contemplating opting out of the NHS Pension Scheme? Examine the potential implications and factors to consider before making a decision.',
              );
              await import('./pages/app-blog1.js');
            },
          },
          {
            path: 'blog/2015-nhs-pension-contributions-benefits',
            component: 'app-blog2',
            action: async () => {
              document.title =
                '2015 NHS Pension Scheme - Contributions vs. Benefits';
              setMetaDescription(
                'Uncover the intricacies of the 2015 NHS Pension Scheme. Understand your contributions and the benefits you stand to gain.',
              );
              await import('./pages/app-blog2.js');
            },
          },
          {
            path: 'blog/nhs-pension-early-retirement-worth-it',
            component: 'app-blog3',
            action: async () => {
              document.title = 'NHS Pension - Is early retirement worth it?';
              setMetaDescription(
                'Explore the feasibility of early retirement with the NHS Pension Scheme. Analyze the pros, cons and financial implications.',
              );
              await import('./pages/app-blog3.js');
            },
          },
          {
            path: 'blog/teachers-pension-scheme-contributions-benefits',
            component: 'app-blog4',
            action: async () => {
              document.title =
                "Teachers' Pension Scheme - Contributions vs. Benefits";
              setMetaDescription(
                "Decipher the Teachers' Pension Scheme. Explore your contributions, the benefits on offer, and the balance between the two.",
              );
              await import('./pages/app-blog4.js');
            },
          },
          {
            path: 'blog/civil-service-pension-scheme-contributions-benefits',
            component: 'app-blog5',
            action: async () => {
              document.title =
                'Civil Service Pension Scheme - Contributions vs. Benefits';
              setMetaDescription(
                "Navigate the Civil Service Pension Scheme. Unravel the details of your contributions and the benefits you'll receive.",
              );
              await import('./pages/app-blog5.js');
            },
          },
          {
            path: 'privacy',
            component: 'app-privacy',
            action: async () => {
              document.title = 'Privacy Policy - PensionCalc';
              await import('./pages/app-privacy.js');
            },
          },
          {
            path: 'terms',
            component: 'app-terms',
            action: async () => {
              document.title = 'Terms of Use - PensionCalc';
              await import('./pages/app-terms.js');
            },
          },
          {
            path: '404',
            component: 'app-404',
            action: async () => {
              document.title = 'Page Not Found - PensionCalc';
              await import('./pages/app-404.js');
            },
          },
          {
            path: '(.*)',
            component: 'app-404',
            action: async () => {
              document.title = 'Page Not Found - PensionCalc';
              await import('./pages/app-404.js');
            },
          },
        ],
      } as any,
    ]);
  }

  render() {
    return html`
      <div>
        <main>
          <div id="routerOutlet"></div>
        </main>
        <!-- <cookie-consent></cookie-consent> -->
      </div>
    `;
  }
}

function setMetaDescription(description: string) {
  const meta = document.querySelector('meta[name="description"]');

  if (meta) {
    meta.setAttribute('content', description);
  } else {
    const newMetaTag = document.createElement('meta');
    newMetaTag.name = 'description';
    newMetaTag.content = description;
    document.head.appendChild(newMetaTag);
  }
}
